import React from 'react';
   import { useDropzone } from 'react-dropzone';
   import './App.css'; // 添加样式

   const App = () => {
     const onDrop = (acceptedFiles) => {
       const file = acceptedFiles[0];
       if (file && file.name === 'music.xlsx') {
         const formData = new FormData();
         formData.append('file', file);
         
         // 调用上传API
         fetch('http://localhost:5000/api/upload', { // 修改为后端API地址
           method: 'POST',
           body: formData,
         })
         .then(response => {
           if (response.ok) {
             alert('上传成功');
           } else {
             alert('上传失败');
           }
         });
       } else {
         alert('请上传名为 "music.xlsx" 的文件');
       }
     };

     const { getRootProps, getInputProps } = useDropzone({ onDrop });

     return (
       <div {...getRootProps()} style={{ border: '2px dashed #0087F7', borderRadius: '50%', padding: '20px', width: '300px', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
         <input {...getInputProps()} />
         <p>拖拽文件到这里，文件名必须是 "music.xlsx"</p>
       </div>
     );
   };

   export default App;